import { Spin } from 'antd'

const Loader = () => {
  return (
    <div className='flex justify-center items-center h-[100vh]'>
      <Spin size='large' fullscreen/>
    </div>
  )
}

export default Loader
