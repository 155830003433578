import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setSession, getSession } from "../../customHooks";
import axios from "axios";
import { useStateGlobalContext } from "../../Context/GlobalContext";

const API_URL = `${process.env.REACT_APP_API_URL}/administration/super-admin-otp-verify/`;

const VerifyOTP = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {appLogoData, getAppLogo} = useStateGlobalContext()

  useEffect(()=>{
    getAppLogo()
  },[])



  const handleVerifyOTP = async (values) => {
    setLoading(true);
    const data = {
      user_id: parseInt(id, 10), // Convert id to number
      otp: parseInt(values.otp, 10), // Convert otp to number
    };
    try {
      const response = await axios.post(API_URL, data);
      if (response.status === 200) {
        const { access, refresh , role} = response.data;
        setSession({ admintoken: access, refreshToken: refresh, role : role });
        navigate("/main");
      } else {
        message.error("UnAuthorized User");
        form.resetFields();
      }
    } catch (error) {
      message.error(error.response.data.error) 
    } finally{
      setLoading(false)
    }
  };

  const backToHome = ()=>{
    navigate('/')
  }

  useEffect(() => {
    if (getSession("admintoken")) {
      navigate("/main");
    }
  }, []);

  return (
    !getSession("admintoken") && (
      <div className="flex justify-center items-center h-screen bg-[#0078D2]">
        {/* <div className="w-1/2 flex justify-center">
         <img src={doctor} alt="doctor"/>
      </div> */}
        <div className="flex justify-center align-middle bg-white p-10 rounded-lg max-w-[380px]">
          <Form layout="vertical" form={form} onFinish={handleVerifyOTP}>
            <div className="flex justify-center py-4">
            <img  src={appLogoData?.icon || logo} alt="EMR Logo" style={{ width: '80%' }}/>
            </div>
            <Form.Item label="Enter OTP" name="otp">
              <Input.Password placeholder="Enter OTP" size="large"/>
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              size="large"
            >
              {loading ? "Loading..." : "Verify OTP"}{" "}
            </Button>
            <div className="flex justify-center">
            <Button type="link" size="large" className="mt-2" onClick={backToHome}>Back</Button>
            </div>
          </Form>
        </div>
      </div>
    )
  );
};

export default VerifyOTP;
