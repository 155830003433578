import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, message, Card, Spin } from "antd";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { doPost } from "../../components/API/apis";
import { setSession, getSession } from "../../customHooks";
import { useStateGlobalContext } from "../../Context/GlobalContext";
import Loader from "../../components/Loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const captchaRef = useRef(null); 
  const [captchaToken, setCaptchaToken] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    appLogoData,
    getAppLogo,
    loading: pageLoading,
  } = useStateGlobalContext();

  useEffect(() => {
    getAppLogo();
  }, []);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Save the reCAPTCHA token
  };

  const resetCaptcha = () => {
    setCaptchaToken(""); // Clear the token state
    if (captchaRef.current) {
      captchaRef.current.reset(); // Reset the reCAPTCHA instance
    }
  };

  const validateCaptcha = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/verify-captcha/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: captchaToken }),
      }
    );

    const result = await response.json();
    return result.message; // Return whether CAPTCHA verification was successful
  };

  const handleLogin = async (values) => {
    setLoading(true);
    if (!captchaToken) {
      Notification.error("Please complete the CAPTCHA!");
      setLoading(false);
      return;
    }

    const isCaptchaValid = await validateCaptcha();
    if (isCaptchaValid !== "CAPTCHA verified!") {
      Notification.error("CAPTCHA verification failed. Please try again.");
      resetCaptcha(); // Reset the CAPTCHA
      setLoading(false);
      return;
    }
    try {
      const response = await doPost(
        `/administration/superadmin-login/`,
        values
      );
      if (response.status === 200) {
        message.success("Please Check your Email to Verify");
        navigate(`/super-admin-otp-verify/${response.data.user_id}`);
        // const { access, refresh, role } = response.data;
        // setSession({ admintoken: access, refreshToken: refresh, userRole: role });
        setLoading(false);
        // navigate('/main')
      } else {
        setLoading(false);
        message.error("UnAuthorized User");
        form.resetFields();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getSession("admintoken")) {
      navigate("/main");
    }
  }, []);

  if (pageLoading) {
    return <Loader />;
  }

  return (
    !getSession("admintoken") && (
      <div className="flex justify-center items-center h-screen bg-[#0078D2]">
        <Card className="bg-white p-4 max-w-[380px] min-w-[380px]">
          <Form layout="vertical" form={form} onFinish={handleLogin}>
            <div className="flex justify-center py-4">
              <img
                src={appLogoData?.icon || logo}
                alt="EMR Logo"
                style={{ width: "80%" }}
              />
            </div>
            {/* <h1 className="text-3xl mb-2 font-semibold text-center mt-4">Login</h1> */}
            <Form.Item label="Username" name="username">
              <Input placeholder="Enter Username" size="large" />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input.Password placeholder="Enter Password" size="large" />
            </Form.Item>

            <ReCAPTCHA
              ref={captchaRef}
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
              onChange={handleCaptchaChange}
            />

            <Button
              size="large"
              className="w-full mt-2 mb-2"
              type={!captchaToken ? "default" : "primary"}
              disabled={!captchaToken}
              htmlType="submit"
              loading={loading}
            >
              {loading ? "Loading" : "Login"}
            </Button>
          </Form>

          <div></div>
        </Card>
      </div>
    )
  );
};

export default Login;
