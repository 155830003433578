import React, { useEffect, useState } from "react";
import { Card, Button, Modal, Form, Upload, message , Image} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {  doPatch, doPost } from "../../../components/API/apis";
import { useStateGlobalContext } from "../../../Context/GlobalContext";
import { fallback } from "./ChangeAuthPageBanner";

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const LogoUpdate = () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false);
  const [appLogo, setAppLogo] = useState(null);
  const {appLogoData, getAppLogo} = useStateGlobalContext()


  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    form.resetFields()
  };

  const officeLogoProps = {
    onRemove: () => {
      setAppLogo(null);
    },
    beforeUpload: (file) => {
      if (file.size > MAX_FILE_SIZE) {
        message.error("File size exceeds the maximum limit (10MB)");
        setAppLogo(null);
        return;
      }
      setAppLogo(file);
      return false;
    },
    maxCount: 1,
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const updateAppLogo = async () => {
    let appLogoModified = null;
    if (appLogo) {
      appLogoModified = await convertToBase64(appLogo);
    } else{
      return message.error("Image is Required")
    }
    const data = {
      icon: appLogoModified,
    };
    try {
      const url = appLogoData?.icon ? "/administration/update-application-images/" : "/administration/add-application-images/";
      const response = appLogoData?.icon ? await doPatch(url, data) : await doPost(url, data);
      if (response.status === 201) {
        message.success("App Logo Added Successfully");
        getAppLogo()
        hideModal()
      } else if(response.status === 200){
        message.success("App Logo Updated Successfully")
        getAppLogo()
        hideModal()
      }
    } catch (error) {
      console.log(error);
    } 
  };


  useEffect(()=>{
    getAppLogo()
  },[])

  return (
    <Card>
      <div className="flex justify-between items-center mb-2">
        <p className="font-bold text-md">Update Logo</p>
        <Button type="primary" onClick={showModal}>
          Update Logo
        </Button>
      </div>
      <div className="text-center">
      <Image
        src={appLogoData && appLogoData?.icon}
        width={400}
        fallback={fallback}
        alt="App Logo"
        preview={appLogoData && appLogoData?.icon}
      />
      </div>

      <Modal open={visible} footer={null} onCancel={hideModal}>
        <Form layout="vertical" form={form} onFinish={updateAppLogo}>
          <Form.Item className="mt-4" label="Office Logo" name='applogo'>
            <Upload
              {...officeLogoProps}
              accept=".jpg, jpeg, .png"
              multiple={false}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={hideModal}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default LogoUpdate;
